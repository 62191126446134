
<template>
	<div>
		<a-table :pagination="false" :rowKey="e => e.id" :columns="columns" :data-source="records" class="white-table">
			<template slot="name" slot-scope="record">
				<template v-if="record.main">
					<a-icon v-if="worksheet.isManual" type="highlight" class="mr-2 text-danger" />
					<a-icon v-else type="file-protect" class="mr-2 text-danger" />
					<span>Main Reservation Agreement</span>
					<em class="ml-1">{{ worksheet.isManual ? '(Print & Sign)' : '(E-Signature)' }}</em>
				</template>
				<template v-else>
					<Icons type="contract-file" style="width:10px;color:var(--red);" class="mr-2" />
					<span>{{ record.name }}</span>
				</template>
			</template>
			<template slot="owner" slot-scope="record">
				<div class="dF aC">
					<a-avatar :size="25" :src="record.owner && record.owner.avatar"
						:icon="record.owner && record.owner.avatar && record.owner.avatar.trim() ? '' : 'user'"
						class="mr-2" />
					<span v-if="record.owner">
						{{ record.owner.firstName }} {{ record.owner.lastName }}
					</span>
				</div>
			</template>
			<template slot="progress" slot-scope="record">
				<span v-if="record.status === 'void'" class="text-danger">VOIDED</span>
				<template v-else-if="record.isManual">
					<span v-if="record.status === 'completed'"><a-icon type="check" class="mr-2 text-success" />
						Completed</span>
					<span v-else>Pending upload</span>
				</template>
				<a-tooltip overlayClassName="change-tooltip-color" v-else-if="record.progress && record.progress.length">
					<template slot="title">
						<div>
							<p v-for="(rec, recI) in record.progress" :key="rec.username + recI" class="dF aC">
								<a-icon :type="rec.icon || 'dash'" class="mr-2" />
								<span class="f1">
									{{ rec.username }} ({{ rec.roleName }})
									<small class="block" v-if="rec.status === 'completed'">
										{{ completedTypeLookup[rec.type] || 'Completed' }}
									</small>
									<small class="block" v-else>
										{{ typeLookup[rec.type] || 'In Queue' }}
									</small>
								</span>
							</p>
						</div>
					</template>
					<span v-if="record.signedSoFar === record.signFull">
						<a-icon type="check" class="mr-2 text-success" /> Completed
					</span>
					<span v-else-if="record.signedSoFar >= 0">
						<a-icon type="user" class="mr-2" />{{ record.signedSoFar }} / {{ record.signFull }} Signed
					</span>
				</a-tooltip>
				<span v-else>
					<span v-if="record.status === 'completed'"><a-icon type="check" class="mr-2 text-success" />
						Completed</span>
					<span v-else>Delivered</span>
				</span>
			</template>
			<template slot="created" slot-scope="record">
				{{ formatDate(record.createdAt) }}
			</template>
			<template slot="action" slot-scope="record">
				<template v-if="editingRights">
					<template v-if="['completed', 'void'].includes(record.status)">
						<div class="dF aC" style="gap: 20px">
							<a-button @click="downloadFile(record)" type="primary" ghost icon="download" size="small">{{
								record.isManual ? 'SIGNED PDF' : 'SIGNED ZIP' }}</a-button>
							<a-button @click="previewFile(record)" type="primary" ghost icon="eye"
								size="small">Preview</a-button>
							<a-button v-if="!requiredMutualRelease && $p > 40" @click="reservationCancellation.reason = ''; voidModal = true" type="primary" ghost icon="scissor" size="small">Void</a-button>
						</div>
					</template>
					<template v-else-if="record.status !== 'completed'">
						<template v-if="isAgent">
							<a-button @click="previewFile(record)" type="primary" ghost icon="eye"
								size="small">Preview</a-button>
						</template>
						<MoreActions v-else :items="moreActionFiltered(record)" :val="record" @action="action" />
					</template>
				</template>
				<template v-else>
				</template>
			</template>
		</a-table>

		<a-modal :visible="inPersonSigning.showModal" @cancel="closeInPersonSigning" :centered="true"
			title="In Person Signing" okText="Process" @ok="processInPersonSigning">
			<a-form-model ref="inPersonSigning" :model="inPersonSigning" class="profile" v-if="inPersonSigning.envelope">
				<a-row type="flex" :gutter="20">
					<a-col :span="24">
						<a-form-model-item label="Select Signer" required
							:rules="[{ required: true, message: 'Please select signer first' }]" prop="signer">
							<a-select v-model="inPersonSigning.signer" size="large">
								<a-select-option v-for="(signer, index) in validInPersonSigners" :key="index"
									:value="signer.email" :title="`${signer.name} (${signer.role}) - ${signer.email}`">
									{{ signer.name }} ({{ signer.role }}) - {{ signer.email }}
								</a-select-option>
							</a-select>
						</a-form-model-item>
					</a-col>
				</a-row>
				<a-alert v-if="!validInPersonSigners.length" type="error"
					message="No valid available Signers found for the current contract template." show-icon />
			</a-form-model>
		</a-modal>
		<a-modal :visible="voidModal" @cancel="closeVoidModal" :centered="true"
			title="Void Reservation" okText="VOID" @ok="voidWorksheetReservation" :confirmLoading="loading">
			<a-form-model ref="reservationCancellation" :model="reservationCancellation" class="profile">
				<p>Are you sure you want to Void Reservation for Worksheet <strong>{{worksheet.readableId}}</strong>?</p>
				<div>Doing this will void this entire reservation <br/>This will void any documents that are pending to be signed and remove any supporting documents and also removes any restrictions from the <b>underlying asset</b> and revert it's status to <b><em>Available</em></b>.</div>
				<a-row type="flex" :gutter="20" class="mt-3">
					<a-col :span="24">
						<a-form-model-item label="Reservation Cancellation Reason"
							:rules="req('Please enter reservation cancellation reason')" prop="reason">
							<a-textarea size="large" v-model="reservationCancellation.reason"
								placeholder="Reservation Cancellation Reason" :rows="3" />
						</a-form-model-item>
					</a-col>
				</a-row>
			</a-form-model>
		</a-modal>
	</div>
</template>

<script>

import Icons from '@/components/common/BHIcons'
import MoreActions from 'bh-mod/components/common/MoreActions'
import { formatDate, downloadURL } from 'bh-mod'
function uploadFile(callback) {
	var input = document.createElement('input');
	input.type = "file";
	input.className = 'bhh-file'
	input.style.display = "none"
	input.onchange = (e) => {
		let file = e.target.files[0];
		callback(file)
		setTimeout(function () {
			input.remove()
		}, 100);
	}
	document.body.appendChild(input);
	setTimeout(function () {
		input.click();
	}, 100);

}
export default {
	components: { Icons, MoreActions, },
	props: {},
	data() {
		return {
			records: [],
			updatedEnvelopes: {},
			typeLookup: {
				cc: 'Carbon Copy',
				sign: 'Signer',
				agent: 'Agent',
			},
			completedTypeLookup: {
				cc: 'Received Copy',
				sign: 'Signed',
			},
			moreActions: [
				{
					label: 'Download Signed Package (.zip)',
					value: 'download',
					icon: 'fe-download',
					type: 'envelope',
					completed: true,
				},
				{
					label: 'Resend',
					value: 'resend',
					icon: 'retweet',
					type: 'envelope',
					isManual: false,
				},
				{
					label: 'Download Contract',
					value: 'downloadManual',
					icon: 'fe-download',
					type: 'envelope',
					isManual: true,
					status: 'created',
				},
				{
					label: 'Upload Signed Contract',
					value: 'uploadSigned',
					icon: 'fe-upload',
					type: 'envelope',
					isManual: true,
					status: 'created',
				},
				{
					label: 'Void',
					value: 'void',
					icon: 'fe-scissors',
					type: 'envelope',
					void: true,
					main: false,
				},
				{
					label: 'Void Reservation',
					value: 'voidReservation',
					icon: 'fe-scissors',
					type: 'envelope',
					void: true,
					main: true,
				},
				{
					label: 'Process In Person Signing',
					value: 'inPersonSigning',
					icon: 'man',
					type: 'inPersonSigning'
				},
				{
					label: 'Preview',
					value: 'preview',
					icon: 'fe-eye',
					type: 'all',
				},
			],
			inPersonSigning: {
				envelope: null,
				signer: null,
				showModal: false
			},
			loading: false,
			voidModal: false,
			reservationCancellation: {
				reason: ''
			}
		}
	},
	watch: {
		'worksheet.reservation.envelops': {
			deep: true,
			handler() {
				this.transformData()
			}
		},
		updatedEnvelopes: {
			handler() {
				this.transformData()
			}
		},
	},
	computed: {
		instance() {
			return this.$store.state.instance
		},
		envelops() {
			return this.worksheet.reservation && this.worksheet.reservation.envelops.filter(x => x.esign || x.isManual) || []
		},
		editingRights() {
			if (this.$p >= 40) {
				return true
			} else {
				return this.worksheet.submittedBy.id === this.user.id
			}
		},
		user() {
			return this.$store.state.user.user
		},
		worksheet() {
			return this.$store.state.appData.selectedWorksheet
		},
		columns() {
			return [
				{
					title: 'Document Name',
					key: 'name',
					scopedSlots: { customRender: 'name' },
				},

				{
					title: 'Owner',
					key: 'owner',
					sign: true,
					scopedSlots: { customRender: 'owner' },
				},

				{
					title: 'Created',
					key: 'created',
					scopedSlots: { customRender: 'created' },
				},
				{
					title: 'Progress',
					key: 'progress',
					sign: true,
					scopedSlots: { customRender: 'progress' },
				},
				{
					title: 'Action',
					key: 'action',
					scopedSlots: { customRender: 'action' },
				},
			]
		},

		validInPersonSigners() {
			let singers = []

			if (this.inPersonSigning.envelope && this.inPersonSigning.envelope.signers) {
				this.inPersonSigning.envelope.signers.forEach(s => {
					s.signers.forEach(ss => {
						if (ss.wantToSignInPerson) {
							singers.push(ss)
						}
					})
				})
			}
			return singers
		},

		isAgent() {
			return this.$userType === 'agent'
		},

		requiredMutualRelease() {
			return this.$store.state.appData.requiredMutualRelease
		},
	},
	methods: {
		req: (msg, required = true) => ({ required: required, message: msg }),

		transformData() {
			let result = []

			this.envelops.forEach(item => {
				let envelope = item
				if (this.updatedEnvelopes[item.id]) envelope = this.updatedEnvelopes[item.id]

				envelope.signedSoFar = 0
				envelope.signFull = 0;
				let signers = ''

				const recipientEvents = envelope.recipientEvents && envelope.recipientEvents.length && envelope.recipientEvents[envelope.recipientEvents.length - 1];
				if (!recipientEvents) {
					const recipients = envelope.envStatus && envelope.envStatus.data && envelope.envStatus.data.envelopeSummary && envelope.envStatus.data.envelopeSummary.recipients;
					signers = recipients && (recipients.signers || []).concat(recipients.carbonCopies || []).concat(recipients.intermediaries || []);

				} else {
					let recipients = recipientEvents && recipientEvents.data && recipientEvents.data.envelopeSummary && recipientEvents.data.envelopeSummary.recipients;
					signers = recipients && recipients.signers || [];
				}

				if (signers && signers.length) {
					const progress = [];

					signers.forEach(s => {
						let username = s.name;
						let status = s.status === 'created' ? 'pending' : s.status === 'delivered' ? 'opened' : s.status;
						let type = s.recipientType === 'signer' ? 'sign' : s.recipientType === 'carboncopy' ? 'cc' : s.recipientType === 'agent' ? 'agent' : s.recipientType.toLowerCase();
						let icon = s.status === 'sent' ? 'mail' : s.status === 'completed' ? 'check' : s.status === 'delivered' ? 'read' : s.status === 'created' ? 'loading' : '';
						let roleName = s.roleName;

						if (type === 'sign') {
							envelope.signFull++
							if (status === 'completed') {
								envelope.signedSoFar++
							}
						}

						progress.push({ username, status, type, icon, roleName })
					})

					envelope.progress = progress;
				}
				result.push(envelope)
			})
			this.records = result
		},

		async downloadFile(record, index = 0) {
			this.$store.commit('LOAD', true)
			let key
			if (record.isManual) {
				index = record.file && record.file.length ? (record.file.length - 1) : index;
				if (!record.file || !record.file[index] || !record.file[index].key) {
					this.$store.commit('LOAD', false)
					return this.$message.error('Cannot find signed PDF file')
				}
				key = record.file[index].key
			} else {
				if (!record.download || !record.download.archive || !record.download.archive.key) {
					this.$store.commit('LOAD', false)
					return this.$message.error('Cannot find signed PDF file')
				}
				key = record.download.archive.key
			}

			try {
				let { data: url } = await this.$api.get(`/contract-templates/:instance/getfile?key=${key}`)
				await downloadURL(url)
			} catch (err) {
				if (!err || !err.response || !err.response.status || err.response.status !== 400) {
					this.$message.error(this.$err(err, 'There was an error downloading the file. Please try again'))
				}
			}
			this.$store.commit('LOAD', false)
		},

		async previewFile(record, index = 0) {
			this.$store.commit('LOAD', true)

			index = record.file && record.file.length ? (record.file.length - 1) : index;
			let key = record.file && record.file[index] && record.file[index].key
			if (record.isManual) {
				if (!record.file || !record.file[index] || !record.file[index].key) {
					this.$store.commit('LOAD', false)
					return this.$message.error('Cannot find signed PDF file')
				}
				key = record.file[index].key
			} else {
				try {
					let { data } = await this.$api.get(`/envelops/:instance/${record.id}/download`);
					record.download = data;
				} catch (error) {
					console.error('Error while fetching latest contract template')
				}

				if ((!record.download || !record.download.combined || !record.download.combined.key) && !key) {
					this.$store.commit('LOAD', false)
					return this.$message.error('Cannot find signed PDF file')
				}
				key = record.download && record.download.combined && record.download.combined.key || key
			}

			try {
				let { data: url } = await this.$api.get(`/contract-templates/:instance/getfile?key=${key}`)
				this.$store.commit('SHOW_PREVIEW', url)
			} catch (err) {
				if (!err || !err.response || !err.response.status || err.response.status !== 400) {
					this.$message.error(this.$err(err, 'There was an error downloading the file. Please try again'))
				}
			}
			this.$store.commit('LOAD', false)
		},

		moreActionFiltered(env) {
			return this.moreActions.filter(mA => {
				if (mA.type === 'all') return true
				else if (mA.type === 'doc') return !env.esign && !env.isManual
				else if (mA.type === 'inPersonSigning') return env.status !== 'completed' && this.checkSignInPerson(env.signers)
				else if (mA.type === 'envelope') {
					if (!env.status) env.status = 'created'
					if (!env.esign && !env.isManual) return false
					if (mA.completed === true) return env.status === 'completed'
					if (mA.completed === false) return env.status !== 'completed'
					if (mA.void) {
						if (env.status.toLowerCase() === 'completed' || env.status.toLowerCase() === 'voided') return false
					}
					if (mA.hasOwnProperty('main') && env.main !== mA.main) {
						return false
					}
					if (mA.hasOwnProperty('isManual') && mA.isManual !== Boolean(env.isManual)) {
						return false
					}
					if (mA.hasOwnProperty('status') && mA.status !== env.status) {
						return false
					}
					return true
				}
			})
		},
		formatDate,
		async action(e) {
			if (e.key === 'downloadDoc') {
				this.$store.commit('LOAD', true)
				let index = e.val && e.val.file && e.val.file.length ? (e.val.file.length - 1) : 0
				let key = e.val.file[index].key
				try {
					let { data: url } = await this.$api.get(`/contract-templates/:instance/getfile?key=${key}`);

					await downloadURL(url, e.val.name)
				} catch (err) {
					if (!err || !err.response || !err.response.status || err.response.status !== 400) {
						this.$message.error(this.$err(err))
					}
				}

				setTimeout(() => {
					this.$store.commit('LOAD', false)
				}, 200);

			} else if (e.key === 'resend') {

				this.$confirm({
					title: `This will resend the envelope to all the recipients.`,
					okText: 'RESEND',
					class: 'test',
					okType: 'success',
					centered: true,
					onOk: () => {
						this.$store.commit('LOAD', true)
						this.$api.post(`/envelops/:instance/${e.val.id}/resend`)
							.then(({ data }) => {
								this.$store.dispatch('FETCH_WORKSHEET', this.worksheet.id);
								return this.$message.success('Successfully resent the envelope!')
							})
							.catch(err => {
								if (!err || !err.response || !err.response.status || err.response.status !== 400) {
									this.$message.error(this.$err(err, 'An error occurred while resending. Please try again!'))
								}
							})
							.finally(() => this.$store.commit('LOAD', false))
					},
				})

			} else if (e.key === 'void') {
				let self = this
				this.$confirm({
					title: `Are you sure you want to VOID this Document (${e.val.name})? This is irreversible!`,
					okText: 'VOID',
					okType: 'danger',
					centered: true,
					onOk() {
						self.$store.commit('LOAD', true)
						self.$api.delete(`/envelops/:instance/${e.val.id}/void`).then(({ data }) => {
							e.val.status = 'voided'
							self.$store.commit('LOAD', false)
						}).catch(err => {
							self.$store.commit('LOAD', false)
							if (!err || !err.response || !err.response.status || err.response.status !== 400) {
								self.$message.error(self.$err(err, 'An error occurred while voiding this document. Please try again!'))
							}
						})
					},
					class: 'test',
				})
			} else if (e.key === 'voidReservation') {
				if (this.$p < 40) return this.$message.error('You do not have permission to void this worksheet reservation')

				let self = this;
				this.$confirm({
					title: "Void Reservation",
					content: (h) => <div>Are you sure you want to Void Reservation for Worksheet <strong>{this.worksheet.readableId}</strong>?</div>,
					okText: "YES, VOID",
					okType: "danger",
					cancelText: "CANCEL",
					centered: true,
					onOk: async () => {
						try {
							await this.$api.post(`/worksheets/${self.instance.id}/${this.worksheet.id}/void-reservation`);
							this.$store.dispatch('FETCH_WORKSHEET', this.worksheet.id);
						} catch (err) {
							if (!err || !err.response || !err.response.status || err.response.status !== 400) {
								this.$message.error(this.$err(err, 'An error occurred while voiding the reservation. Please try again!'))
							}
						}
					},
				});
			} else if (e.key === 'uploadSigned') {
				let env = e.val
				let label = env.name
				uploadFile((file) => {
					this.$confirm({
						title: 'Confirm Upload',
						content: h => <div class="my-2">Please confirm that this file is the Signed copy of <strong style="display:inline-block">{label}</strong></div>,
						okText: 'Continue',
						okType: 'primary',
						cancelText: 'Cancel',
						centered: true,
						onOk: () => {
							this.$store.commit('SHOW_MANUAL_UPLOAD', { file, buttonText: 'UPLOAD', callback: this.uploadSignedFile.bind(this, env, file) })
						}
					})
				})
			} else if (e.key === 'downloadManual') {
				let envelope = e.val
				this.downloadFile(envelope, 0)
			} else if (e.key === 'preview') {
				this.previewFile(e.val)
			} else if (e.key === 'inPersonSigning') {
				this.inPersonSigning = {
					showModal: true,
					envelope: e.val
				}
			}
		},
		async processInPersonSigning() {
			this.$store.commit('LOAD', true)
			try {
				const signer = this.validInPersonSigners.find(s => s.email === this.inPersonSigning.signer)
				const { data } = await this.$api.post(`/envelops/:instance/${this.inPersonSigning.envelope.id}/get-in-person-recipient-signing-url`, {
					returnUrl: window.location.href,
					signer: signer
				})
				this.$store.commit('LOAD', false)
				this.closeInPersonSigning()
				if (data.url) {
					window.location.href = data.url
				}
			} catch (err) {
				this.$store.commit('LOAD', false)
				this.closeInPersonSigning()
				if (!err || !err.response || !err.response.status || err.response.status !== 400) {
					this.$message.error(this.$err(err, 'Error while processing offline signing. Please try again'))
				}
			}
		},

		closeInPersonSigning() {
			this.inPersonSigning = { envelope: null, signer: null, showModal: false }
		},

		checkSignInPerson(signers) {
			if (!signers) {
				return false;
			}
			return signers.some(s => {
				return s.signers.some(ss => ss.wantToSignInPerson)
			})
		},

		uploadSignedFile(env, file) {
			this.$store.commit('LOAD', true)
			let fD = new FormData()
			fD.append('file', file)

			this.$api.put(`/envelops/:instance/${env.id}/file`, fD, { headers: { 'Content-Type': 'multipart/form-data' } }).then(({ data }) => {
				this.$store.dispatch('FETCH_WORKSHEET', this.worksheet.id)
				this.updatedEnvelopes[data.id] = data
			}).catch(err => {
				if (!err || !err.response || !err.response.status || err.response.status !== 400) {
					this.$message.error(this.$err(err, 'An error occurred while uploading the file. Please try again!'))
				}
			}).finally(() => {
				this.$store.commit('LOAD', false)
				this.$store.commit("CLOSE_PREVIEW");
			})
		},

		async voidWorksheetReservation() {
			let self = this
			this.$refs.reservationCancellation.validate(async (valid) => {
				if (valid) {
					self.loading = true;
					self.$api.post(`/worksheets/${self.instance.id}/${self.worksheet.id}/void-reservation`, { reason: self.reservationCancellation.reason }).then(() => {
						self.$refs.reservationCancellation.resetFields()
						self.voidModal = false;
						self.$message.success('Selected Worksheet Reservation voided successfully')

						self.$store.dispatch('FETCH_WORKSHEET', this.worksheet.id);
					}).catch((err) => {
						if (!err || !err.response || !err.response.status || err.response.status !== 400) {
							self.$message.error(self.$err(err, `An error occurred while voiding reservation. Please try again.`))
						}
					}).finally(() => self.loading = false);
				}
			})
		},

		closeVoidModal() {
			this.$refs.reservationCancellation.resetFields();
			this.voidModal = false;
		},
	},
	created() {
		this.transformData()

	}
}
</script>

<style lang="scss">
</style>
