<template>
	<div class="w-full">
		<EnvelopeModal :visible="createModal.show" :type="createModal.type" :worksheet="currentWorksheet"
			@close="closeCreateModal" />

		<a-card :bodyStyle="{ padding: 0, borderTop: '1px solid var(--light-purple)' }" title="Agreement of Reservation">
			<EnvelopeTable :key="$store.state.appData.newStoreTime" />
			<template slot="actions" v-if="editingRights && needsRelease && mainSigned && !['void', 'sold'].includes(currentWorksheet.status) && requiredMutualRelease">
				<div class="text-left w-full pl-4" style="cursor: auto;">
					<a-tooltip overlayClassName="change-tooltip-color" title="Release Envelopes can be used to void this worksheet reservation">
						<a href="#" @click.prevent="e => createEnvelope('release')"
							style="display:inline-block; cursor:pointer;width:auto" class="hover:text-primary">
							<a-icon type="plus-circle" class="mr-2" />
							<span>Add Notice of Mutual Release</span>
						</a>
					</a-tooltip>
				</div>
			</template>
		</a-card>
	</div>
</template>

<script>
import EnvelopeTable from '@/components/common/EnvelopeTable'
import EnvelopeModal from '@/components/common/EnvelopeModal'

export default {
	components: {
		EnvelopeTable,
		EnvelopeModal,
	},
	data() {
		return {
			createModal: {
				show: false,
				type: 'regular',
			},
		}
	},

	computed: {
		mainSigned() {
			return this.currentWorksheet.reservation && this.currentWorksheet.reservation.envelops.find(e => e.main && e.status.includes('complete')) || false
		},
		requiredMutualRelease() {
			return this.$store.state.appData.requiredMutualRelease
		},
		needsRelease() {
			return this.editingRights && this.$store.getters.needsRelease
		},
		editingRights() {
			if (this.currentWorksheet.status === 'void' || this.isAgent) return false

			if (this.$p >= 40) {
				return true
			} else {
				return this.currentWorksheet.submittedBy.id === this.user.id
			}
		},
		user() {
			return this.$store.state.user.user
		},
		currentWorksheet() {
			return this.$store.state.appData.selectedWorksheet || {};
		},
		envelops() {
			return this.currentWorksheet.reservation && this.currentWorksheet.reservation.envelops.filter(x => x.esign || x.isManual) || []
		},
		instance() {
			return this.$store.state.instance
		},
		isAgent() {
			return this.$userType === 'agent'
		},
	},
	methods: {
		createEnvelope(type = 'regular') {
			this.createModal = {
				show: true,
				type
			}
		},
		closeCreateModal() {
			this.createModal.show = false
		},
	},
}
</script>

<style>
</style>
