<template>
	<div class="dF py-4 aC w-full participant-card">
		<div class="dF f1">
			<div class="participant-icon">
				<i :class="item.icon || `fe fe-user`" v-if="!item.invalid" />
				<a-tooltip overlayClassName="change-tooltip-color" v-else title="Missing info">
					<a-icon class="text-danger" type="warning" />
				</a-tooltip>
			</div>
			<div class="f1 pl-4">
				<span class="text-black">{{ item.name }}</span>
				<small class=block>{{ item.description || '' }}</small>
			</div>
		</div>
		<div class="dF">
			<div v-if="$route.params.id && !editingRights" class="participant-action" @click="$emit('view', item.id)">
				<i :class="item.icon || `fe fe-eye`" />
			</div>
			<template v-if="editingRights">
				<div class="participant-action" @click="$emit('edit', item.id)">
					<i :class="item.icon || `fe fe-edit-2`" />
				</div>
				<div class="ml-4 participant-action" @click="$emit('trash', item.id)">
					<i :class="item.icon || `fe fe-trash-2`" />
				</div>
			</template>
			<template v-else-if="['approved', 'reserved', 'void', 'sold'].includes(worksheet.status)">
				<a-tooltip overlayClassName="change-tooltip-color" :title="`This worksheet is ${worksheet.status}, therefore cannot be edited`">
					<div class="participant-action">
						<i class="fe fe-lock" />
					</div>
				</a-tooltip>
			</template>
		</div>
	</div>
</template>

<script>
export default {
	props: ['item'],
	computed: {
		editingRights() {
			if (this.worksheet.status == 'pending' && !this.worksheet.id) return true;
			if (['approved'].includes(this.worksheet.status) && !this.isMainEnvelopSigned && (!this.worksheet.reservation || (this.worksheet.reservation.createdBy === this.user.id))) return true
			if (['approved', 'reserved', 'void', 'sold'].includes(this.worksheet.status)) return false

			return this.worksheet.submittedBy.id === this.user.id
		},
		user() {
			return this.$store.state.user.user
		},
		worksheet() {
			return this.$store.state.appData.selectedWorksheet || { status: 'pending' };
		},
		isAgent() {
			return this.$userType === 'agent'
		},

		isMainEnvelopSigned() {
			if (!this.worksheet.reservation || !this.worksheet.reservation.envelops) {
				return false
			}
			let envelope = this.worksheet.reservation.envelops.filter(x => x.main)
			let isSigned = false;

			if (envelope && envelope.length) {
				envelope = envelope[0];
				let signers = ''

				const recipientEvents = envelope.recipientEvents && envelope.recipientEvents.length && envelope.recipientEvents[envelope.recipientEvents.length - 1];
				if (!recipientEvents) {
					return
				}

				let recipients = recipientEvents && recipientEvents.data && recipientEvents.data.envelopeSummary && recipientEvents.data.envelopeSummary.recipients;
				signers = recipients && recipients.signers || [];

				if (signers && signers.length) {
					signers.forEach(s => {
						if (s.status === 'completed') {
							isSigned = true
						}
					})
				}
			}

			return isSigned;
		},
	},
}
</script>

<style lang="scss">
.participant-icon,
.participant-action {
	width: 40px;
	height: 40px;
	border-radius: 50%;
	display: flex;
	place-items: center;
	place-content: center;
	font-size: 20px;
	color: currentColor;
}

.participant-icon {
	border: 1px solid currentColor;
}

.participant-card {
	border-bottom: 1px solid var(--light-purple);
}

.participant-action {
	cursor: pointer;

	&:focus,
	&:active {
		background: var(--light-purple);
	}

	&:hover {
		background: var(--very-light-purple);
	}

}
</style>
