<template>
	<a-modal :visible="noteModal.visible" @cancel="onClose" @ok="onClose" :footer="null" centered>
		<h5 class="mb-4">{{ noteType === 'edit' ? 'Edit Note' : noteType === 'add' ? 'Add New Note' : '' }}</h5>
		<a-form-model ref="ruleForm" :model="note" :rules="rules">
			<a-col :span="24">
				<a-form-model-item label="Subject" prop="subject">
					<a-input v-model="note.subject" size="large" />
				</a-form-model-item>
			</a-col>
			<a-col :span="24">
				<a-form-model-item label="Note" prop="content">
					<a-textarea v-model="note.content" :auto-size="{ minRows: 5, maxRows: 5 }" placeholder="Add note" />
				</a-form-model-item>
			</a-col>
		</a-form-model>

		<div style="text-align: right;">
			<a-button @click="onClose" class="mt-3 mr-3" size="large">CANCEL</a-button>
			<a-button @click="onSubmit" type="primary" size="large">
				{{ noteType === 'edit' ? 'UPDATE' : noteType === 'add' ? 'CREATE' : '' }}
			</a-button>
		</div>
	</a-modal>
</template>

<script>

	export default {
		data() {
			return {
				note: {
					subject: '',
					content: '',
					createdBy: '',
					updatedAt: Date.now()
				},
				rules: {
					subject: [
						{ required: true, message: 'Please input a subject', trigger: 'blur' },
					],
				},
			}
		},
		watch: {
			noteType(val) {
				if (val == 'edit') {
					let note = this.currentNotes.find(x => x.id === this.noteModal.note)
					this.note = JSON.parse(JSON.stringify(note))
				}
			}
		},
		computed: {
			worksheet() {
				return this.$store.state.appData.selectedWorksheet
			},
			currentNotes() {
				let result = this.worksheet.notes || []
				return result
			},
			noteType() {
				return this.$store.state.appData.note.type
			},
			noteModal() {
				return this.$store.state.appData.note
			},
			instance() {
				return this.$store.state.instance
			},
			user() {
				return this.$store.state.user.user || {};
			},
		},
		methods: {
			onClose() {
				this.$store.commit('CLOSE_NOTE_MODAL')
				this.note = {
					subject: '',
					content: '',
					id: Date.now() + '',
					createdBy: '',
				}
			},
			onSubmit() {
				this.$refs.ruleForm.validate(async valid => {
					if (valid) {
						let currentNotes = this.currentNotes
						if (this.noteType == 'edit') {
							let notes = currentNotes.map(x => x.id === this.note.id ? { ...this.note, updatedAt: Date.now(), createdBy: this.user.id || '' } : x)
							this.$api.put(`/worksheets/:instance/${this.worksheet.id}`, { notes }).then(() => {
								this.$store.commit('SET_PROP', { where: ['selectedWorksheet', 'notes'], what: notes })
								this.onClose()
							}).catch((err) => {
								if (!err || !err.response || !err.response.status || err.response.status !== 400) {
									this.$message.error(this.$err(err))
								}
							})
						} else {
							let id = Date.now() + ''
							this.note.id = id
							this.note.createdBy = this.user.id || ''
							this.note.updatedAt = Date.now()

							currentNotes.push(this.note)

							this.$api.put(`/worksheets/:instance/${this.worksheet.id}`, { notes: currentNotes }).then(() => {
								this.$store.commit('SET_PROP', { where: ['selectedWorksheet', 'notes'], what: currentNotes })
								this.onClose()
							}).catch((err) => {
								if (!err || !err.response || !err.response.status || err.response.status !== 400) {
									this.$message.error(this.$err(err))
								}
							})

							this.onClose()
						}
					} else {
						return false;
					}
				});
			}
		},
	}
</script>
