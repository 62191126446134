<template>
	<div class="w-full dF fC f1 px-4 pb-4 hide-scrollbar" style="overflow: scroll; background-color: #f7f5f9">
		<div class="dF aC jSB">
			<div class="dF aC" style="gap: 20px">
				<a-icon type="arrow-left" style="font-size: 25px;" @click="goBack" />
				<div style="font-size: 30px;">{{ !worksheetId ? 'Add Worksheet' : (worksheet.readableId || 'Edit Worksheet')
				}} </div>
				<div v-if="worksheetId" class="py-1 px-3 text-center">
					<span class="badge-offer tT" :class="classLookup(worksheet.status).color">
						{{ classLookup(worksheet.status).text }}
					</span>
				</div>
			</div>
			<div class="dF aC mr-3" style="gap: 20px">
				<div v-if="$route.params.id" class="dF aC cursor-pointer" @click="fetchWorksheet">
					<a-tooltip overlayClassName="change-tooltip-color">
						<template slot="title">Refresh</template>
						<a-icon type="sync" style="font-size: 20px; color: var(--primary);" />
					</a-tooltip>
				</div>
				<div v-if="(worksheetId && editingRights)">
					<a-button type="danger" @click="deleteWorksheet" ghost>
						<a-icon type="delete" />
						DELETE
					</a-button>
				</div>
			</div>
		</div>
		<BHLoading :show="loading" />

		<a-modal :visible="worksheetFormModal" @cancel="$router.push('/')" :centered="true" title="Worksheet Form"
			okText="ADD NEW WORKSHEET" @ok="createNew">
			<a-form-model ref="createNewWorksheet" :model="createNewWorksheet" class="profile">
				<a-form-model-item label="Select Worksheet Form" required
					:rules="{ required: true, message: 'Please select a worksheet form' }" prop="id">
					<a-select v-model="createNewWorksheet.id" size="large" option-label-prop="label">
						<a-select-option v-for="worksheetForm in worksheetForms" :key="worksheetForm.id"
							:value="worksheetForm.id" :label="worksheetForm.name">
							{{ worksheetForm.name }}
						</a-select-option>
					</a-select>
				</a-form-model-item>
			</a-form-model>
		</a-modal>

		<a-tabs v-if="!worksheetFormModal" class="worksheetTabs hide-scrollbar" v-model="currentTab"
			style="overflow-y:scroll">
			<a-tab-pane key="details" tab="Details">
				<a-card v-if="isFormReady">
					<a-form-model ref="formData" :model="formData" class="profile">
						<!-- worksheet purchaser and solicitor field will be fill up in this component -->
						<PurchaserForm :worksheetFields="formData" @setFn="setValidatingFn" @updateWorksheet="onSubmit(true)" />

						<div class="mt-4" v-for="(field, index) in worksheetFields" :key="index">
							<!-- Lot field -->
							<template v-if="field.type === 'lot' && formData.lots">
								<h4 class="mb-3" style="color: #40454c;">{{ field.label }}</h4>
								<a-row :gutter="16">
									<a-col :span="24">
										<div style="display: flex; justify-content: space-between;">
											<a-col style="width: 100%;">
												<a-form-model-item :label="field.label1" :required="field.required1"
													:rules="req(field.required1, 'Please select your lot choice #1')"
													prop="lots[0]">
													<a-select style="width: 100%;" size="large" v-model="formData.lots[0]"
														:placeholder="field.placeholder1"
														:allowClear="!['approved', 'reserved', 'void', 'sold'].includes(worksheet.status) && isAgent">
														<a-select-option v-for="(lot, index) in firstLotChoices"
															:key='index + lot.id' :value="lot.id"
															:disabled="!['available', 'allocated'].includes(lot.status)">
															Lot {{ lot.name}} ({{ statusList[lot.status] || lot.status}})
														</a-select-option>
													</a-select>
												</a-form-model-item>
											</a-col>
											<a-col v-if="field.showSecondChoice" style="width: 100%;">
												<a-form-model-item :label="field.label2" :required="field.required2"
													:rules="req(field.required2, 'Please select your lot choice #2')"
													prop="lots[1]">
													<a-select style="width: 100%;" size="large" v-model="formData.lots[1]"
														:placeholder="field.placeholder2"
														:allowClear="!['approved', 'reserved', 'void', 'sold'].includes(worksheet.status) && isAgent">
														<a-select-option v-for="(lot, index) in secondLotChoices"
															:key='index + lot.id' :value="lot.id"
															:disabled="!['available', 'allocated'].includes(lot.status)">
															Lot {{ lot.name }} ({{ statusList[lot.status] || lot.status}})
														</a-select-option>
													</a-select>
												</a-form-model-item>
											</a-col>
											<a-col v-if="field.showThirdChoice" style="width: 100%;">
												<a-form-model-item :label="field.label3" :required="field.required3"
													:rules="req(field.required3, 'Please select your lot choice #3')"
													prop="lots[2]">
													<a-select style="width: 100%;" size="large" v-model="formData.lots[2]"
														:placeholder="field.placeholder3"
														:allowClear="!['approved', 'reserved', 'void', 'sold'].includes(worksheet.status) && isAgent">
														<a-select-option v-for="(lot, index) in thirdLotChoices"
															:key='index + lot.id' :value="lot.id"
															:disabled="!['available', 'allocated'].includes(lot.status)">
															Lot {{ lot.name }} ({{ statusList[lot.status] || lot.status}})
														</a-select-option>
													</a-select>
												</a-form-model-item>
											</a-col>
										</div>
									</a-col>
								</a-row>
								<a-row :gutter="16" class="mx-1">
									<a-col v-show="formData.lots[0]" :span="8">
										<h6 v-if="loadingLotUnits[0]" class="mt-3">Loading Units ...</h6>
										<h6 v-else-if="(formData.units[0] === false || lotUnits[0].length === 0) && !loadingLotUnits[0]" class="text-danger mt-3">There are no available units for this lot. Please select another lot.</h6>
										<a-form-model-item v-else :label="`Select the Model Elevation for ${field.label1}`" :required="field.required1"
											:rules="req(field.required1, `Please select the Model Elevation for ${field.label1}`)"
											prop="units[0]">
											<a-select style="width: 100%;" size="large" v-model="formData.units[0]"
												:placeholder="field.placeholder1"
												:allowClear="!['approved', 'reserved', 'void', 'sold'].includes(worksheet.status) && isAgent">
												<a-select-option v-for="(unit, index) in lotUnits[0]"
													:key='index + unit.id' :value="unit.id">
													{{unit.unitGroup && unit.unitGroup.name}} - <em>{{unit.name}} </em> <span v-if="unit.unitGroup.isStandingInventory"> (Standing Inventory)</span>
												</a-select-option>
											</a-select>
										</a-form-model-item>
										<h6 v-if="lotUnitsError[0]" class="text-danger mt-3">
											<span v-if="(formData.units[0] === false || lotUnits[0].length === 0) && !loadingLotUnits[0]">
												Please update a lot selection to get available units to move forward.
											</span>
											<span v-else>
												Please select a unit to move forward.
											</span>
										</h6>
									</a-col>
									<a-col v-show="field.showSecondChoice && formData.lots[1]" :span="8">
										<h6 v-if="loadingLotUnits[1]" class="mt-3">Loading Units ...</h6>
										<h6 v-else-if="(formData.units[1] === false || lotUnits[1].length === 0) && !loadingLotUnits[1]" class="text-danger mt-3">There are no available units for this lot. Please select another lot.</h6>
										<a-form-model-item v-else :label="`Select the Model Elevation for ${field.label2}`" :required="field.required2"
											:rules="req(field.required2, `Please select the Model Elevation for ${field.label2}`)"
											prop="units[1]">
											<a-select style="width: 100%;" size="large" v-model="formData.units[1]"
												:placeholder="field.placeholder2"
												:allowClear="!['approved', 'reserved', 'void', 'sold'].includes(worksheet.status) && isAgent">
												<a-select-option v-for="(unit, index) in lotUnits[1]"
													:key='index + unit.id' :value="unit.id">
													{{unit.unitGroup && unit.unitGroup.name}} - <em>{{unit.name}} </em> <span v-if="unit.unitGroup.isStandingInventory"> (Standing Inventory)</span>
												</a-select-option>
											</a-select>
										</a-form-model-item>
										<h6 v-if="lotUnitsError[1]" class="text-danger mt-3">
											<span v-if="(formData.units[1] === false || lotUnits[1].length === 0) && !loadingLotUnits[1]">
												Please update a lot selection to get available units to move forward.
											</span>
											<span v-else>
												Please select a unit to move forward.
											</span>
										</h6>
									</a-col>
									<a-col v-show="field.showThirdChoice && formData.lots[2]" :span="8">
										<h6 v-if="loadingLotUnits[2]" class="mt-3">Loading Units ...</h6>
										<h6 v-else-if="(formData.units[2] === false || lotUnits[2].length === 0) && !loadingLotUnits[2]" class="text-danger mt-3">There are no available units for this lot. Please select another lot.</h6>
										<a-form-model-item v-else :label="`Select the Model Elevation for ${field.label3}`" :required="field.required3"
											:rules="req(field.required3, `Please select the Model Elevation for ${field.label3}`)"
											prop="units[2]">
											<a-select style="width: 100%;" size="large" v-model="formData.units[2]"
												:placeholder="field.placeholder3"
												:allowClear="!['approved', 'reserved', 'void', 'sold'].includes(worksheet.status) && isAgent">
												<a-select-option v-for="(unit, index) in lotUnits[2]"
													:key='index + unit.id' :value="unit.id">
													{{unit.unitGroup && unit.unitGroup.name}} - <em>{{unit.name}} </em> <span v-if="unit.unitGroup.isStandingInventory"> (Standing Inventory)</span>
												</a-select-option>
											</a-select>
										</a-form-model-item>
										<h6 v-if="lotUnitsError[2]" class="text-danger mt-3">
											<span v-if="(formData.units[2] === false || lotUnits[2].length === 0) && !loadingLotUnits[2]">
												Please update a lot selection to get available units to move forward.
											</span>
											<span v-else>
												Please select a unit to move forward.
											</span>
										</h6>
									</a-col>
								</a-row>
								<a-row :gutter="16" class="mx-1">
									<a-col :span="8">
										<a-form-model-item v-show="formData.units[0] && lotUnitPackages[0].length" :label="`Select the Floorplan Package for ${field.label1}`" :required="field.required1"
											:rules="req(field.required1, `Please select the Floorplan Package for ${field.label1}`)"
											prop="packages[0]">
											<a-select style="width: 100%;" size="large" v-model="formData.packages[0]">
												<a-select-option v-for="pkg in lotUnitPackages[0]" :key='pkg.id' :value="pkg.id">
													{{pkg.name}}
												</a-select-option>
											</a-select>
										</a-form-model-item>
									</a-col>
									<a-col :span="8">
										<a-form-model-item v-show="formData.units[1] && lotUnitPackages[1].length" :label="`Select the Floorplan Package for ${field.label2}`" :required="field.required2"
											:rules="req(field.required2, `Please select the Floorplan Package for ${field.label2}`)"
											prop="packages[1]">
											<a-select style="width: 100%;" size="large" v-model="formData.packages[1]">
												<a-select-option v-for="pkg in lotUnitPackages[1]" :key='pkg.id' :value="pkg.id">
													{{pkg.name}}
												</a-select-option>
											</a-select>
										</a-form-model-item>
									</a-col>
									<a-col :span="8">
										<a-form-model-item v-show="formData.units[2] && lotUnitPackages[2].length" :label="`Select the Floorplan Package for ${field.label3}`" :required="field.required3"
											:rules="req(field.required3, `Please select the Floorplan Package for ${field.label3}`)"
											prop="packages[2]">
											<a-select style="width: 100%;" size="large" v-model="formData.packages[2]">
												<a-select-option v-for="pkg in lotUnitPackages[2]" :key='pkg.id' :value="pkg.id">
													{{pkg.name}}
												</a-select-option>
											</a-select>
										</a-form-model-item>
									</a-col>
								</a-row>
							</template>
							<!-- Unit field -->
							<template v-else-if="field.type === 'unit' && formData.units">
								<h4 class="mb-3" style="color: #40454c;">{{ field.label }}</h4>
								<a-row :gutter="16">
									<a-col :span="24">
										<div style="display: flex; justify-content: space-between;">
											<a-col style="width: 100%;">
												<a-form-model-item :label="field.label1" :required="field.required1"
													:rules="req(field.required1, 'Please select your unit choice #1')"
													prop="units[0]">
													<a-select style="width: 100%;" size="large" v-model="formData.units[0]"
														:placeholder="field.placeholder1"
														:allowClear="!['approved', 'reserved', 'void', 'sold'].includes(worksheet.status) && isAgent">
														<a-select-option v-for="(unit, index) in firstUnitChoices"
															:key='index + unit.id' :value="unit.id"
															:disabled="!['available', 'allocated'].includes(unit.salesStatus)">Unit
															{{ unit.unitNumber
															}} ({{ statusList[unit.salesStatus] || unit.salesStatus
}})</a-select-option>
													</a-select>
												</a-form-model-item>
											</a-col>
											<a-col v-if="field.showSecondChoice" style="width: 100%;">
												<a-form-model-item :label="field.label2" :required="field.required2"
													:rules="req(field.required2, 'Please select your unit choice #2')"
													prop="units[1]">
													<a-select style="width: 100%;" size="large" v-model="formData.units[1]"
														:placeholder="field.placeholder2"
														:allowClear="!['approved', 'reserved', 'void', 'sold'].includes(worksheet.status) && isAgent">
														<a-select-option v-for="(unit, index) in secondUnitChoices"
															:key='index + unit.id' :value="unit.id"
															:disabled="!['available', 'allocated'].includes(unit.salesStatus)">Unit
															{{ unit.unitNumber
															}} ({{ statusList[unit.salesStatus] || unit.salesStatus
}})</a-select-option>
													</a-select>
												</a-form-model-item>
											</a-col>
											<a-col v-if="field.showThirdChoice" style="width: 100%;">
												<a-form-model-item :label="field.label3" :required="field.required3"
													:rules="req(field.required3, 'Please select your unit choice #3')"
													prop="units[2]">
													<a-select style="width: 100%;" size="large" v-model="formData.units[2]"
														:placeholder="field.placeholder3"
														:allowClear="!['approved', 'reserved', 'void', 'sold'].includes(worksheet.status) && isAgent">
														<a-select-option v-for="(unit, index) in thirdUnitChoices"
															:key='index + unit.id' :value="unit.id"
															:disabled="!['available', 'allocated'].includes(unit.salesStatus)">Unit
															{{ unit.unitNumber
															}} ({{ statusList[unit.salesStatus] || unit.salesStatus
}})</a-select-option>
													</a-select>
												</a-form-model-item>
											</a-col>
										</div>
									</a-col>
								</a-row>
							</template>
							<!-- Add Ons field -->
							<template v-else-if="field.type === 'addOns' && formData.addons">
								<h4 class="mb-3" style="color: #40454c;">{{ field.label }}</h4>
								<a-row :gutter="16">
									<a-col :span="24">
										<div style="display: flex; justify-content: space-between;">
											<a-col v-if="field.parking.show" style="width: 100%;">
												<a-form-model-item :label="field.parking.label"
													:required="field.parking.required"
													:rules="req(field.parking.required, 'Please select your parking choice')"
													prop="addons.parking">
													<a-select style="width: 100%;" size="large"
														v-model="formData.addons.parking"
														:mode="field.parking.allowMultiple ? 'multiple' : 'default'"
														:placeholder="field.parking.placeholder">
														<a-select-option v-for="(parking, index) in addOns.parking"
															:key='index + parking.id' :value="parking.id"
															:disabled="!['available', 'allocated'].includes(parking.status)">Parking
															{{ parking.pID }} #{{ parking.name }} ({{
																statusList[parking.status] ||
																parking.status }})</a-select-option>
													</a-select>
												</a-form-model-item>
											</a-col>
											<a-col v-if="field.lockers.show" style="width: 100%;">
												<a-form-model-item :label="field.lockers.label"
													:required="field.lockers.required"
													:rules="req(field.lockers.required, 'Please select your locker choice')"
													prop="addons.lockers">
													<a-select style="width: 100%;" size="large"
														v-model="formData.addons.lockers"
														:mode="field.lockers.allowMultiple ? 'multiple' : 'default'"
														:placeholder="field.lockers.placeholder">
														<a-select-option v-for="(locker, index) in addOns.lockers"
															:key='index + locker.id' :value="locker.id"
															:disabled="!['available', 'allocated'].includes(locker.status)">Locker
															{{ locker.lID }} #{{ locker.name }} ({{
																statusList[locker.status] ||
																locker.status }})</a-select-option>
													</a-select>
												</a-form-model-item>
											</a-col>
											<a-col v-if="field.bikeRacks.show" style="width: 100%;">
												<a-form-model-item :label="field.bikeRacks.label"
													:required="field.bikeRacks.required"
													:rules="req(field.bikeRacks.required, 'Please select your bike racks choice')"
													prop="addons.bikeRacks">
													<a-select style="width: 100%;" size="large"
														v-model="formData.addons.bikeRacks"
														:mode="field.bikeRacks.allowMultiple ? 'multiple' : 'default'"
														:placeholder="field.bikeRacks.placeholder">
														<a-select-option v-for="(bikeRack, index) in addOns.bikeRacks"
															:key='index + bikeRack.id' :value="bikeRack.id"
															:disabled="!['available', 'allocated'].includes(bikeRack.status)">BikeRack
															{{ bikeRack.bID }} #{{ bikeRack.name }} ({{
																statusList[bikeRack.status] ||
																bikeRack.status }})</a-select-option>
													</a-select>
												</a-form-model-item>
											</a-col>
										</div>
									</a-col>
								</a-row>
							</template>
							<!-- Purchaser field -->
							<template v-else-if="field.type === 'purchaser' && formData.purchasers">
								<h4 class="mb-3" style="color: #40454c;">{{ field.label }}</h4>
								<a-row :gutter="16">
									<a-col :span="24" style="padding: 0 16px;">
										<div>
											<a-row :gutter="16">
												<div style="display: flex; justify-content: space-between;">
													<a-col style="width: 100%;">
														<a-form-model-item label="First Name"
															:rules="req(field.required, 'Please enter purchaser first name')"
															:prop="`purchasers[${field.index}].firstName`">
															<a-input size="large" type="text"
																v-model="formData.purchasers[field.index].firstName"
																placeholder="Purchaser's First Name">
															</a-input>
														</a-form-model-item>
													</a-col>
													<a-col style="width: 100%;">
														<a-form-model-item label="Last Name"
															:rules="req(field.required, 'Please enter purchaser last name')"
															:prop="`purchasers[${field.index}].lastName`">
															<a-input size="large" type="text"
																v-model="formData.purchasers[field.index].lastName"
																placeholder="Purchaser's Last Name">
															</a-input>
														</a-form-model-item>
													</a-col>
													<a-col style="width: 100%;">
														<a-form-model-item label="Middle Name">
															<a-input size="large" type="text"
																v-model="formData.purchasers[field.index].middleName"
																placeholder="Purchaser's Middle Name">
															</a-input>
														</a-form-model-item>
													</a-col>
												</div>
											</a-row>
											<a-row :gutter="16">
												<div style="display: flex; justify-content: space-between;">
													<a-col style="width: 100%;">
														<a-form-model-item label="Date of Birth"
															:rules="req(field.required, 'Please enter purchaser birth date')"
															:prop="`purchasers[${field.index}].dob`">
															<a-date-picker placeholder="eg. 1984-02-28"
																v-model="formData.purchasers[field.index].dob"
																:disabled-date="disabledDate" size="large"
																style="width: 100%;"></a-date-picker>
														</a-form-model-item>
													</a-col>
													<a-col style="width: 100%;">
														<a-form-model-item label="Email"
															:rules="req(field.required, 'Please enter purchaser email')"
															:prop="`purchasers[${field.index}].email`">
															<a-input size="large" type="text"
																v-model="formData.purchasers[field.index].email"
																placeholder="Purchaser's Email">
															</a-input>
														</a-form-model-item>
													</a-col>
													<a-col style="width: 100%;">
														<a-form-model-item label="Phone #"
															:rules="req(field.required, 'Please enter purchaser phone number')"
															:prop="`purchasers[${field.index}].phone`">
															<a-input-number size="large" type="text"
																v-model="formData.purchasers[field.index].phone"
																placeholder="Purchaser's Phone Number" style="width: 100%;">
															</a-input-number>
														</a-form-model-item>
													</a-col>
												</div>
											</a-row>
											<a-row :gutter="16">
												<div style="display: flex; justify-content: space-between;">
													<a-col style="width: 100%;">
														<a-form-model-item label="Company" type="text"
															v-model="formData.purchasers[field.index].company"
															placeholder="Purchaser's Company">
															<a-input size="large">
															</a-input>
														</a-form-model-item>
													</a-col>
													<a-col style="width: 100%;">
														<a-form-model-item label="Business Phone #">
															<a-input-number size="large" type="text"
																v-model="formData.purchasers[field.index].business"
																placeholder="Purchaser's Business Number"
																style="width: 100%;">
															</a-input-number>
														</a-form-model-item>
													</a-col>
													<a-col style="width: 100%;">
														<a-form-model-item label="Cell #">
															<a-input-number size="large" type="text"
																v-model="formData.purchasers[field.index].cell"
																placeholder="Purchaser's Cell Number" style="width: 100%;">
															</a-input-number>
														</a-form-model-item>
													</a-col>
												</div>
											</a-row>
											<a-row :gutter="16">
												<div style="display: flex; justify-content: space-between;">
													<a-col style="width: 100%;">
														<a-form-model-item label="Job Title">
															<a-input size="large" type="text"
																v-model="formData.purchasers[field.index].jobTitle"
																placeholder="Purchaser's Job title">
															</a-input>
														</a-form-model-item>
													</a-col>
													<a-col style="width: 100%;">
														<a-form-model-item label="SSN/SIN #">
															<a-input size="large" type="text"
																v-model="formData.purchasers[field.index].ssnsin"
																placeholder="Purchaser's SSN/SIN">
															</a-input>
														</a-form-model-item>
													</a-col>
													<a-col style="width: 100%;">
														<a-form-model-item label="Identification Type"
															:rules="req(field.required, 'Please select Identification type')"
															:prop="`purchasers[${field.index}].idType`">
															<a-select v-model="formData.purchasers[field.index].idType"
																style="width: 100%;" size="large">
																<a-select-option value="pp">US/CA Passport</a-select-option>
																<a-select-option value="other">Other</a-select-option>
																<a-select-option v-for="(option, optionI) in field.options"
																	:key='optionI + option.option' :value="option.option">{{
																		option.option
																	}}</a-select-option>

															</a-select>
														</a-form-model-item>
													</a-col>
												</div>
											</a-row>
											<a-row :gutter="16">
												<div style="display: flex; justify-content: space-between;">
													<a-col style="width: 100%;">
														<a-form-model-item label="Driver License #"
															:rules="formData.purchasers[field.index].passportOtherValue ? [] : req(field.required, 'Please enter purchaser driver license #')"
															:prop="`purchasers[${field.index}].idValue`">
															<a-input style="width:100%" size="large" type="text"
																v-model="formData.purchasers[field.index].idValue"
																placeholder="Purchaser's Driver license #"
																@change="clearValidation('passportOtherValue', field.index)">
															</a-input>
														</a-form-model-item>
													</a-col>
													<a-col style="width: 100%;">
														<a-form-model-item label="License Issuing Jurisdiction">
															<a-input size="large" type="text"
																v-model="formData.purchasers[field.index].issuing"
																placeholder="Purchaser's License Issuing Jurisdiction">
															</a-input>
														</a-form-model-item>
													</a-col>
													<a-col style="width: 100%;">
														<a-form-model-item label="License Expiry">
															<a-date-picker placeholder="eg. 1984-02-28"
																v-model="formData.purchasers[field.index].expiry"
																:disabled-date="disabledExpiryDate" size="large"
																style="width: 100%;"></a-date-picker>
														</a-form-model-item>
													</a-col>
												</div>
											</a-row>
											<a-row :gutter="16">
												<div style="display: flex; justify-content: space-between;">
													<a-col style="width: 100%;">
														<a-form-model-item
															:label="formData.purchasers[field.index].idType === 'pp' ? 'Passport #' : 'Identification #'"
															:rules="formData.purchasers[field.index].idValue ? [] : req(field.required, 'Please enter purchaser identification value')"
															:prop="`purchasers[${field.index}].passportOtherValue`">
															<a-input style="width:100%" size="large" type="text"
																v-model="formData.purchasers[field.index].passportOtherValue"
																:placeholder="`Purchaser's ${formData.purchasers[field.index].idType === 'pp' ? 'Passport #' : 'Other #'}`"
																@change="clearValidation('idValue', field.index)">
															</a-input>
														</a-form-model-item>
													</a-col>
													<a-col style="width: 100%;">
														<a-form-model-item
															:label="formData.purchasers[field.index].idType === 'pp' ? 'Passport Issuing Jurisdiction' : 'Other Issuing Jurisdiction'">
															<a-input size="large" type="text"
																v-model="formData.purchasers[field.index].passportOtherIssuing"
																:placeholder="`Purchaser's ${formData.purchasers[field.index].idType === 'pp' ? 'Passport Issuing Jurisdiction' : 'Other Issuing Jurisdiction'}`">
															</a-input>
														</a-form-model-item>
													</a-col>
													<a-col style="width: 100%;">
														<a-form-model-item
															:label="formData.purchasers[field.index].idType === 'pp' ? 'Passport Expiry' : 'Other Expiry'">
															<a-date-picker placeholder="eg. 1984-02-28"
																v-model="formData.purchasers[field.index].passportOtherExpiry"
																:disabled-date="disabledExpiryDate" size="large"
																style="width: 100%;"></a-date-picker>
														</a-form-model-item>
													</a-col>
												</div>
											</a-row>
											<a-row :gutter="16">
												<a-col :span="12">
													<a-form-model-item label="Address Line 1"
														:rules="req(field.required, 'Please enter purchaser address')"
														:prop="`purchasers[${field.index}].address1`">
														<a-input size="large" type="text"
															v-model="formData.purchasers[field.index].address1"
															placeholder="Purchaser's Address line 1">
														</a-input>
													</a-form-model-item>
												</a-col>
												<a-col :span="12">
													<a-form-model-item label="Address Line 2">
														<a-input size="large" type="text"
															v-model="formData.purchasers[field.index].address2"
															placeholder="Purchaser's Address line 2">
														</a-input>
													</a-form-model-item>
												</a-col>
											</a-row>
											<a-row :gutter="16">
												<div style="display: flex; justify-content: space-between;">
													<a-col :span="6">
														<a-form-model-item label="City"
															:rules="req(field.required, 'Please enter purchaser city')"
															:prop="`purchasers[${field.index}].city`">
															<a-input size="large" type="text"
																v-model="formData.purchasers[field.index].city"
																placeholder="Purchaser's City">
															</a-input>
														</a-form-model-item>
													</a-col>
													<a-col :span="6">
														<a-form-model-item label="State/Province"
															:rules="req(field.required, 'Please enter purchaser state/province')"
															:prop="`purchasers[${field.index}].region`">
															<a-input size="large" type="text"
																v-model="formData.purchasers[field.index].region"
																placeholder="Purchaser's State/Province">
															</a-input>
														</a-form-model-item>
													</a-col>
													<a-col :span="6">
														<a-form-model-item label="ZIP/Postal Code"
															:rules="req(field.required, 'Please enter purchaser zip/postal code')"
															:prop="`purchasers[${field.index}].postal`">
															<a-input size="large" type="text"
																v-model="formData.purchasers[field.index].postal"
																placeholder="Purchaser's ZIP/Postal Code">
															</a-input>
														</a-form-model-item>
													</a-col>
													<a-col :span="6">
														<a-form-model-item label="Country"
															:rules="req(field.required, 'Please enter purchaser country')"
															:prop="`purchasers[${field.index}].country`">
															<a-input size="large" type="text"
																v-model="formData.purchasers[field.index].country"
																placeholder="Purchaser's Country" />
														</a-form-model-item>
													</a-col>
												</div>
											</a-row>
										</div>
									</a-col>
								</a-row>
							</template>
							<!-- Solicitor field -->
							<template v-else-if="field.type === 'solicitor' && formData.solicitor">
								<h4 class="mb-3" style="color: #40454c;">{{ field.label }}</h4>
								<a-row :gutter="16">
									<a-col :span="24" style="padding: 0 16px;">
										<div>
											<a-row :gutter="16">
												<a-col :span="8">
													<a-form-model-item label="First Name"
														:rules="req(field.required, 'Please enter lawyer first name')"
														:prop="`solicitor.firstName`">
														<a-input size="large" type="text"
															v-model="formData.solicitor.firstName"
															placeholder="Lawyer's First Name">
														</a-input>
													</a-form-model-item>
												</a-col>
												<a-col :span="8">
													<a-form-model-item label="Last Name"
														:rules="req(field.required, 'Please enter lawyer last name')"
														:prop="`solicitor.lastName`">
														<a-input size="large" type="text"
															v-model="formData.solicitor.lastName"
															placeholder="Lawyer's Last Name">
														</a-input>
													</a-form-model-item>
												</a-col>
												<a-col :span="8">
													<a-form-model-item label="Email"
														:rules="req(field.required, 'Please enter lawyer email')"
														:prop="`solicitor.email`">
														<a-input size="large" type="text" v-model="formData.solicitor.email"
															placeholder="Lawyer's Email">
														</a-input>
													</a-form-model-item>
												</a-col>
											</a-row>
											<a-row :gutter="16">
												<a-col :span="8">
													<a-form-model-item label="Phone #">
														<a-input size="large" type="text" v-model="formData.solicitor.phone"
															placeholder="Lawyer's Phone Number">
														</a-input>
													</a-form-model-item>
												</a-col>
												<a-col :span="8">
													<a-form-model-item label="Fax #">
														<a-input size="large" type="text" v-model="formData.solicitor.fax"
															placeholder="Lawyer's Cell Number">
														</a-input>
													</a-form-model-item>
												</a-col>
												<a-col :span="8">
													<a-form-model-item label="Company" type="text"
														v-model="formData.solicitor.company" placeholder="Lawyer's Company">
														<a-input size="large">
														</a-input>
													</a-form-model-item>
												</a-col>
											</a-row>
											<a-row :gutter="16">
												<a-col :span="8">
													<a-form-model-item label="Job Title">
														<a-input size="large" type="text"
															v-model="formData.solicitor.jobTitle"
															placeholder="Lawyer's Job title">
														</a-input>
													</a-form-model-item>
												</a-col>
												<a-col :span="8">
													<a-form-model-item label="Address Line 1"
														:rules="req(field.required, 'Please enter lawyer address')"
														:prop="`solicitor.address1`">
														<a-input size="large" type="text"
															v-model="formData.solicitor.address1"
															placeholder="Lawyer's Address line 1">
														</a-input>
													</a-form-model-item>
												</a-col>
												<a-col :span="8">
													<a-form-model-item label="Address Line 2">
														<a-input size="large" type="text"
															v-model="formData.solicitor.address2"
															placeholder="Lawyer's Address line 2">
														</a-input>
													</a-form-model-item>
												</a-col>
											</a-row>
											<a-row :gutter="16">
												<a-col :span="6">
													<a-form-model-item label="City"
														:rules="req(field.required, 'Please enter lawyer city')"
														:prop="`solicitor.city`">
														<a-input size="large" type="text" v-model="formData.solicitor.city"
															placeholder="Lawyer's City">
														</a-input>
													</a-form-model-item>
												</a-col>
												<a-col :span="6">
													<a-form-model-item label="State/Province"
														:rules="req(field.required, 'Please enter lawyer state/province')"
														:prop="`solicitor.region`">
														<a-input size="large" type="text"
															v-model="formData.solicitor.region"
															placeholder="Lawyer's State/Province">
														</a-input>
													</a-form-model-item>
												</a-col>
												<a-col :span="6">
													<a-form-model-item label="ZIP/Postal Code"
														:rules="req(field.required, 'Please enter lawyer zip/postal code')"
														:prop="`solicitor.postal`">
														<a-input size="large" type="text"
															v-model="formData.solicitor.postal"
															placeholder="Lawyer's ZIP/Postal Code">
														</a-input>
													</a-form-model-item>
												</a-col>
												<a-col :span="6">
													<a-form-model-item label="Country"
														:rules="req(field.required, 'Please enter lawyer country')"
														:prop="`solicitor.country`">
														<a-input size="large" type="text"
															v-model="formData.solicitor.country"
															placeholder="Lawyer's Country" />
													</a-form-model-item>
												</a-col>
											</a-row>
										</div>
									</a-col>
								</a-row>
							</template>

							<!-- Email Field -->
							<template v-else-if="field.type === 'email'">
								<a-row :gutter="16">
									<a-col :span="24">
										<a-form-model-item :label="field.label || 'Email'"
											:rules="req(field.required, 'Please enter email')" prop="email">
											<a-input size="large" type="text" v-model="formData.email"
												:placeholder="field.placeholder">
											</a-input>
										</a-form-model-item>
									</a-col>
								</a-row>
							</template>

							<!-- Name Field -->
							<template v-else-if="field.type === 'name' && formData.name">
								<a-row :gutter="16">
									<a-col :span="24">
										<div style="display: flex; justify-content: space-between;">
											<a-col v-show="field.prefix" style="width: 30%;">
												<a-form-model-item label="Prefix" prop="name.prefix">
													<a-input v-model="formData.name.prefix" type="text" size="large">
													</a-input>
												</a-form-model-item>
											</a-col>
											<a-col style="width: 100%;">
												<a-form-model-item label="First Name" prop="name.firstName"
													:rules="req(field.required, 'Please enter first name')">
													<a-input v-model="formData.name.firstName" type="text" size="large">
													</a-input>
												</a-form-model-item>
											</a-col>
											<a-col v-show="field.middleInitial" style="width: 30%;">
												<a-form-model-item label="Middle Initial" prop="name.middleName">
													<a-input v-model="formData.name.middleName" type="text" size="large">
													</a-input>
												</a-form-model-item>
											</a-col>
											<a-col style="width: 100%;">
												<a-form-model-item label="Last Name" prop="name.lastName"
													:rules="req(field.required, 'Please enter last name')">
													<a-input v-model="formData.name.lastName" type="text" size="large">
													</a-input>
												</a-form-model-item>
											</a-col>
											<a-col v-show="field.suffix" style="width: 30%;">
												<a-form-model-item label="Suffix" prop="name.suffix">
													<a-input v-model="formData.name.suffix" type="text" size="large">
													</a-input>
												</a-form-model-item>
											</a-col>
										</div>
									</a-col>
								</a-row>
							</template>

							<!-- Email Field -->
							<template v-else-if="field.type === 'phone'">
								<a-row :gutter="16">
									<a-col :span="24">
										<a-form-model-item :label="field.label || 'Phone'"
											:rules="req(field.required, 'Please enter phone')" prop="phone">
											<a-input size="large" type="text" v-model="formData.phone"
												:placeholder="field.placeholder">
											</a-input>
										</a-form-model-item>
									</a-col>
								</a-row>
							</template>

							<!-- Address Field -->
							<template v-else-if="field.type === 'address' && formData.address">
								<a-row :gutter="16">
									<a-col :span="24">
										<a-row :gutter="16">
											<a-col :span="24">
												<a-form-model-item label="Address Line 1"
													:rules="req(field.required, 'Please enter address')"
													prop="address.address1">
													<a-input size="large" type="text" v-model="formData.address.address1"
														placeholder="Address line 1">
													</a-input>
												</a-form-model-item>
											</a-col>
										</a-row>
										<a-row :gutter="16">
											<a-col :span="24">
												<a-form-model-item label="Address Line 2">
													<a-input size="large" type="text" v-model="formData.address.address2"
														placeholder="Address line 2">
													</a-input>
												</a-form-model-item>
											</a-col>
										</a-row>
										<a-row :gutter="16">
											<div style="display: flex; justify-content: space-between;">
												<a-col :span="6">
													<a-form-model-item label="City"
														:rules="req(field.required, 'Please enter city')"
														prop="address.city">
														<a-input size="large" type="text" v-model="formData.address.city"
															placeholder="City">
														</a-input>
													</a-form-model-item>
												</a-col>
												<a-col :span="6">
													<a-form-model-item label="State/Province"
														:rules="req(field.required, 'Please enter state/province')"
														prop="address.region">
														<a-input size="large" type="text" v-model="formData.address.region"
															placeholder="State/Province">
														</a-input>
													</a-form-model-item>
												</a-col>
												<a-col :span="6">
													<a-form-model-item label="ZIP/Postal Code"
														:rules="req(field.required, 'Please enter zip/postal code')"
														prop="address.postal">
														<a-input size="large" type="text" v-model="formData.address.postal"
															placeholder="ZIP/Postal Code">
														</a-input>
													</a-form-model-item>
												</a-col>
												<a-col :span="6">
													<a-form-model-item label="Country"
														:rules="req(field.required, 'Please enter country')"
														prop="address.country">
														<a-input size="large" type="text" v-model="formData.address.country"
															placeholder="Country" />
													</a-form-model-item>
												</a-col>
											</div>
										</a-row>
									</a-col>
								</a-row>
							</template>

							<!-- Agent Field -->
							<template v-else-if="field.type === 'isAgent'">
								<a-row :gutter="16">
									<a-col :span="24">
										<a-form-model-item :label="field.label || 'isAgent'"
											:rules="req(field.required, 'Please input your value')" prop="isAgent">
											<template v-if="field.displayType == 'checkbox'">
												<a-checkbox v-model="formData.isAgent"
													style="display: block; height: 32px; margin-left: 0 !important;"
													size="large">
												</a-checkbox>
											</template>


											<template v-else-if="field.displayType == 'radio'">
												<a-radio-group v-model="formData.isAgent" size="large">
													<a-radio style="display: block; height: 32px;" :value="1">
														Yes
													</a-radio>
													<a-radio style="display: block; height: 32px;" :value="0">
														No
													</a-radio>
												</a-radio-group>
											</template>


											<template v-else>
												<a-select style="width: 100%;" size="large" :placeholder="field.placeholder"
													v-model="formData.isAgent">
													<a-select-option :value="1">Yes</a-select-option>
													<a-select-option :value="0">No</a-select-option>
												</a-select>
											</template>
										</a-form-model-item>
									</a-col>
								</a-row>
							</template>

							<!-- Text Field -->
							<template v-else-if="field.type === 'text'">
								<a-row :gutter="16">
									<a-col :span="24">
										<a-form-model-item :label="field.label"
											:rules="req(field.required, `Please enter value of ${field.label}`)"
											:prop="field.id">
											<a-input size="large" type="text" v-model="formData[field.id]"
												:placeholder="field.placeholder">
											</a-input>
										</a-form-model-item>
									</a-col>
								</a-row>
							</template>

							<!-- Number Field -->
							<template v-else-if="field.type === 'number'">
								<a-row :gutter="16">
									<a-col :span="24">
										<a-form-model-item :label="field.label"
											:rules="req(field.required, `Please enter value of ${field.label}`)"
											:prop="field.id">
											<a-input-number size="large" v-model="formData[field.id]"
												:placeholder="field.placeholder"
												:min="field.min ? Number(field.min) : -Infinity"
												:max="field.max ? Number(field.max) : Infinity" style="width: 100%;">
											</a-input-number>
										</a-form-model-item>
									</a-col>
								</a-row>
							</template>

							<!-- Dropdown Field -->
							<template v-else-if="field.type === 'dropdown'">
								<a-row :gutter="16">
									<a-col :span="24">
										<a-form-model-item :label="field.label"
											:rules="req(field.required, 'Please select the option')" :prop="field.id">
											<a-select v-model="formData[field.id]" :placeholder="field.placeholder"
												size="large" style="width: 100%;">
												<a-select-option v-for="(option, optionI) in field.options"
													:key='optionI + option.option' :value="option.option">{{ option.option
													}}</a-select-option>
											</a-select>
										</a-form-model-item>
									</a-col>
								</a-row>
							</template>

							<!-- Radio Field -->
							<template v-else-if="field.type === 'radio'">
								<a-row :gutter="16">
									<a-col :span="24">
										<a-form-model-item :label="field.label"
											:rules="req(field.required, 'Please select the option')" :prop="field.id">
											<a-radio-group v-model="formData[field.id]" size="large">
												<template>
													<a-radio v-for="(option, optionI) in field.options"
														style="display: block; height: 32px;" :value="option.option"
														:key="option + optionI">
														{{ option.option }}
													</a-radio>
												</template>
												<a-radio v-if="field.other" style="display: block; height: 32px;"
													value="other">
													<span class="mr-2" style="padding-left: 2px;" v-if="field.otherText">{{
														field.otherText }}</span><a-input
														:style="{ width: 100, marginLeft: 10 }" />
												</a-radio>
											</a-radio-group>
										</a-form-model-item>
									</a-col>
								</a-row>
							</template>

							<!-- Checkbox Field -->
							<template v-else-if="field.type === 'checkbox'">
								<a-row :gutter="16">
									<a-col :span="24">
										<a-form-model-item :label="field.label"
											:rules="req(field.required, 'Please select the option')" :prop="field.id">
											<template v-if="field.multiple">
												<a-checkbox-group v-model="formData[field.id]" size="large">
													<template>
														<a-checkbox v-for="(option, optionI) in field.options"
															style="display: block; height: 32px; margin-left: 0 !important;"
															:value="option.option" :key="option + optionI">
															{{ option.option }}
														</a-checkbox>
													</template>
													<a-checkbox v-if="field.other"
														style="display: flex; align-items: center; height: 32px; margin-left: 0 !important;"
														value="other">
														<div class="dF aC">
															<span class="mr-2" style="padding-left: 2px;"
																v-if="field.otherText">{{
																	field.otherText }}</span><a-input
																:style="{ width: 100, marginLeft: 10 }" />
														</div>
													</a-checkbox>
												</a-checkbox-group>
											</template>
											<template v-else>
												<a-checkbox v-model="formData[field.id]" size="large"
													style="display: block; height: 32px; margin-left: 0 !important;"
													:value="field.label">
													{{ field.label }}
												</a-checkbox>
											</template>
										</a-form-model-item>
									</a-col>
								</a-row>
							</template>

							<!-- Date/Time Field -->
							<template v-else-if="field.type === 'date'">
								<a-row :gutter="16">
									<a-col :span="24">
										<a-form-model-item :label="field.label"
											:rules="req(field.required, `Please select value of ${field.label}`)"
											:prop="field.id">
											<a-date-picker v-model="formData[field.id]" :placeholder="field.placeholder"
												:showTime="field.timeEnable" style="width: 100%" size="large">
											</a-date-picker>
										</a-form-model-item>
									</a-col>
								</a-row>
							</template>

							<!-- File Field -->
							<template v-else-if="field.type === 'file'">
								<a-row :gutter="16">
									<a-col :span="24">
										<a-form-model-item :label="field.label"
											:rules="req(field.required, 'Please upload a file')" :prop="field.id">
											<a-upload :name="field.buttonLabel" v-model="formData[field.id]"
												:showUploadList="false" :beforeUpload="(e) => beforeUpload(e, field)"
												@change="(e) => handleChange(e, field)"> <a-button> <a-icon type="upload" />
													{{ field.buttonLabel }} </a-button> </a-upload>
										</a-form-model-item>
									</a-col>
								</a-row>
							</template>

							<!-- Tag Field -->
							<template v-else-if="field.type === 'tag'">
								<a-row :gutter="16">
									<a-col :span="24">
										<a-form-model-item :label="field.label"
											:rules="req(field.required, `Please select value of ${field.label}`)"
											:prop="field.id">
											<a-select v-model="formData[field.id]" :placeholder="field.placeholder"
												:mode="field.multiple ? 'tags' : 'default'" size="large"
												style="width: 100%;">
												<a-select-option v-for="(option, optionI) in field.options"
													:key='optionI + option.option' :value="option.option">{{ option.option
													}}</a-select-option>
											</a-select>
										</a-form-model-item>
									</a-col>
								</a-row>
							</template>

							<!-- Header Field -->
							<template v-else-if="field.type === 'header'">
								<a-row :gutter="16">
									<a-col :span="24">
										<div style="font-size: 18px;">
											{{ field.label }}
										</div>
										<div v-if="field.description && field.description.length" class="mt-2">
											{{ field.description }}
										</div>
									</a-col>
								</a-row>
							</template>

							<a-divider />
						</div>

					</a-form-model>

					<div>
						<div class="dF jSB mt-3">
							<a-button size="large" class="mr-5" type="secondary" @click="goBack">BACK</a-button>
							<a-button size="large" type="primary" @click="onSubmit" :disabled="!editingRights">
								{{ !worksheetId ? 'SUBMIT' : 'UPDATE' }}
							</a-button>
						</div>
					</div>
				</a-card>
			</a-tab-pane>
			<a-tab-pane key="docs" tab="Documents" v-if="isFormReady && worksheet.reservation">
				<documentsTab />
			</a-tab-pane>
			<a-tab-pane key="notes" tab="Notes" v-if="worksheetId">
				<notesTab />
			</a-tab-pane>
		</a-tabs>
	</div>
</template>

<script>
import BHLoading from "bh-mod/components/common/Loading";
import documentsTab from '@/components/common/documentsTab'
import notesTab from '@/components/common/notesTab'
import PurchaserForm from '@/components/common/PurchaserForm'

import moment from "moment";
import { getBase64 } from "bh-mod";

export default {
	components: {
		BHLoading,
		documentsTab,
		notesTab,
		PurchaserForm
	},
	data() {
		return {
			loading: false,
			isFormReady: false,
			worksheetFormModal: false,
			currentTab: 'details',
			worksheetId: '',
			createNewWorksheet: {
				id: null
			},
			purchaserFieldValidateFn: () => console.log(null),
			formData: {},
			defaultPurchaserFieldData: {
				firstName: '',
				middleName: '',
				lastName: '',
				email: '',
				phone: '',
				dob: null,
				business: '',
				cell: '',
				issuing: '',
				expiry: null,
				company: '',
				occupation: '',
				idType: 'pp',
				idValue: '',
				passportOtherValue: '',
				passportOtherIssuing: '',
				passportOtherExpiry: null,
				address1: '',
				address2: '',
				postal: '',
				country: '',
				region: '',
				city: '',
				jobTitle: '',
				ssnsin: ''
			},
			defaultSolicitorFieldData: {
				firstName: '',
				lastName: '',
				email: '',
				fax: '',
				phone: '',
				company: '',
				jobTitle: '',
				address1: '',
				address2: '',
				country: '',
				region: '',
				city: '',
				postal: '',
			},
			statusList: {
				"available": "Available",
				"sold": "Sold",
				"hold": "Hold",
				"soldConditional": "Sold Conditional",
				"conditional": "Conditional",
				"inventory": "Inventory",
				"notreleased": "Not Released",
				"allocated": "Allocated",
				"approved_for_reservation": "Approved For Reservation",
				"reserved": "Reserved"
			},
			lotUnits: [[], [], []],
			lotUnitsError:[false, false, false],
			lotUnitPackages: [[], [], []],
			loadingLotUnits: {0: false, 1: false, 2: false}
		};
	},
	computed: {
		instance() {
			return this.$store.state.instance
		},
		worksheet() {
			return this.$store.state.appData.selectedWorksheet || { status: 'pending' };
		},
		worksheetForm() {
			return this.$store.state.appData.selectedWorksheetForm;
		},
		worksheetForms() {
			return this.$store.state.appData.worksheetForms;
		},
		worksheetFields() {
			if (this.worksheetForm) {
				// worksheet purchaser and solicitor field will be fill up in PurchaserForm component so excluding from the form list
				return this.worksheetForm.fields.filter(f => !f.hide && !['purchaser', 'solicitor'].includes(f.type))
			}
			return []
		},
		solicitorField() {
			if (this.worksheetForm) {
				return this.worksheetForm.fields.find(f => !f.hide && f.type === 'solicitor')
			}
			return false
		},
		lots() {
			return this.$store.state.appData.lots && this.$store.state.appData.lots.filter(l => l.type === 'lot' && l.name && !['sold', 'soldConditional', 'conditional', 'notreleased', 'inventory'].includes(l.status)) || [];
		},
		firstLotChoices() {
			return this.lots.filter(l => this.formData.lots && ![this.formData.lots[1], this.formData.lots[2]].includes(l.id))
		},
		secondLotChoices() {
			return this.lots.filter(l => this.formData.lots && ![this.formData.lots[0], this.formData.lots[2]].includes(l.id))
		},
		thirdLotChoices() {
			return this.lots.filter(l => this.formData.lots && ![this.formData.lots[0], this.formData.lots[1]].includes(l.id))
		},
		units() {
			return this.$store.state.appData.units && this.$store.state.appData.units.filter(u => !['soldConditional', 'conditional', 'notreleased', 'inventory'].includes(u.salesStatus)) || [];
		},
		addOns() {
			const addOns = this.$store.state.appData.addOns || { parking: [], lockers: [], bikeRacks: [] }
			if (addOns.parking && addOns.parking.length) {
				addOns.parking = addOns.parking.filter(
					(p) => !p.unitId && ((p.status === 'allocated' && p.allocatedTo === this.user.id) || p.status !== 'allocated')
				);
			}

			if (addOns.lockers && addOns.lockers.length) {
				addOns.lockers = addOns.lockers.filter(
					(l) => !l.unitId && ((l.status === 'allocated' && l.allocatedTo === this.user.id) || l.status !== 'allocated')
				);
			}

			if (addOns.bikeRacks && addOns.bikeRacks.length) {
				addOns.bikeRacks = addOns.bikeRacks.filter(
					(b) => !b.unitId && ((b.status === 'allocated' && b.allocatedTo === this.user.id) || b.status !== 'allocated')
				);
			}
			return {
				parking: [...(addOns.parking || [])],
				lockers: [...(addOns.lockers || [])],
				bikeRacks: [...(addOns.bikeRacks || [])]
			}
		},
		firstUnitChoices() {
			return this.units.filter(u => this.formData.units && ![this.formData.units[1], this.formData.units[2]].includes(u.id))
		},
		secondUnitChoices() {
			return this.units.filter(u => this.formData.units && ![this.formData.units[0], this.formData.units[2]].includes(u.id))
		},
		thirdUnitChoices() {
			return this.units.filter(u => this.formData.units && ![this.formData.units[0], this.formData.units[1]].includes(u.id))
		},

		isAgent() {
			return this.$userType === 'agent'
		},
		user() {
			return this.$store.state.user.user
		},
		editingRights() {
			if (this.worksheet.status == 'pending' && !this.worksheet.id) return true;
			if (['approved', 'reserved', 'void', 'sold'].includes(this.worksheet.status)) return false

			return (this.worksheet.submittedBy && this.worksheet.submittedBy.id) === (this.user && this.user.id)
		},
	},

	watch: {
		'formData.lots.0': {
			handler(lotId) {
				this.fetchLotUnits(lotId, 0)
			}
		},
		'formData.lots.1': {
			handler(lotId) {
				this.fetchLotUnits(lotId, 1)
			}
		},
		'formData.lots.2': {
			handler(lotId) {
				this.fetchLotUnits(lotId, 2)
			}
		},
		'formData.units.0': {
			handler(unitId) {
				this.getLotUnitPackages(unitId, 0)
			}
		},
		'formData.units.1': {
			handler(unitId) {
				this.getLotUnitPackages(unitId, 1)
			}
		},
		'formData.units.2': {
			handler(unitId) {
				this.getLotUnitPackages(unitId, 2)
			}
		},
	},

	created() {
		this.worksheetId = this.$route.params.id || null;
		if (this.worksheetId) {
			this.fetchWorksheet();
			this.$store.dispatch('FETCH_CONTRACT_TEMPLATE')
		} else {
			this.generateWorksheetData()
		}
	},
	methods: {
		req: (required, msg) => ({ required: required, message: msg }),

		async fetchLotUnits(lotId, index) {
			this.$set(this.lotUnitsError, index, false)
			if (!lotId) {
				this.$set(this.lotUnits, index, [])
				this.$set(this.formData.units, index, null)
				return;
			}
			this.loadingLotUnits[index] = true;
			this.$set(this.lotUnitPackages, index, [])
			try {
				const { data } = await this.$api.get(`/architectural-controls/:instance/availableunits/${lotId}`)
				let units = data.units && data.units.filter(unit => {
					return unit.unitGroup && unit.unitGroup.id && unit.packages && unit.packages.length
				}) || []
				if (units.length === 0) {
					this.loadingLotUnits[index] = false;
					return this.formData.units[index] === false
				}

				this.$set(this.lotUnits, index, units)
				if (this.formData.units[index] && this.formData.packages[index]) {
					this.getLotUnitPackages(this.formData.units[index], index)
				}
			} catch (err) {
				if (!err || !err.response || !err.response.status || err.response.status !== 400) {
					this.$message.error(this.$err(err, 'Error while fetching unit list. Please select another unit'))
				}
			} finally {
				this.loadingLotUnits[index] = false;
			}
		},

		getLotUnitPackages(unitId, index) {
			if (this.instance.productType === 'highrise') return;
			let units = this.lotUnits[index]
			if (unitId) {
				let unit = units.find(x => x.id === unitId && x.packages && x.packages.length)
				if (!unit) {
					return;
				}
				this.$set(this.lotUnitsError, index, false)
				this.$set(this.lotUnitPackages, index, [...unit.packages])
				if (!unit.packages.find(p => p.id === this.formData.packages[index])) {
					this.$set(this.formData.packages, index, unit.packages[0].id)
				}
			} else {
				this.$set(this.lotUnitPackages, index, [])
				this.$set(this.formData.packages, index, null)
			}
		},

		async fetchWorksheet() {
			this.loading = true;
			try {
				const { data } = await this.$api.get(`/worksheets/${this.instance.id}/${this.worksheetId}`);
				this.$store.commit('SET_WORKSHEET', data)
				if (data.data && data.data.fields) {
					data.data.fields.forEach(f => {
						let value = f.value
						if (isNaN(value) && !isNaN(Date.parse(value))) {
							this.formData[f.key] = moment(value);
							this.$set(this.formData, f.key, moment(value))
						} else {
							if (f.key === 'purchasers' && value) {
								value = value.map(v => {
									return {
										...v,
										idType: v.idType === 'dl' ? 'pp' : v.idType
									}
								})
							}
							this.$set(this.formData, f.key, value)
						}
					})
				}
				this.isFormReady = true;
			} catch (error) {
				console.error('Error occurred while fetching worksheet list', error)
			}
			this.loading = false;
		},

		clearValidation(field, index) {
			this.$refs.formData.clearValidate(`purchasers[${index}].${field}`)
		},

		async onSubmit(purchaserUpdate = false) {
			if (!this.editingRights && !purchaserUpdate) {
				return;
			}
			let result = await this.purchaserFieldValidateFn();

			this.$refs.formData.validate(async (valid) => {
				if (valid) {
					if (!result) {
						return;
					} else {
						this.formData.purchasers = [...result.purchasers]
						if (this.solicitorField) {
							this.formData.solicitor = result.solicitor && result.solicitor.email ? result.solicitor : {}
						}
					}

					// manual check for unit and package selection if lot is select for any of choice
					if (this.instance.productType === 'lowrise') {
						let lots = this.formData.lots;
						let units = this.formData.units;
						let moveForward = true;

						if (lots[0] && !units[0]) {
							moveForward = false;
							this.$set(this.lotUnitsError, 0, true)
						}
						if (lots[1] && !units[1]) {
							moveForward = false;
							this.$set(this.lotUnitsError, 1, true)
						}
						if (lots[2] && !units[2]) {
							moveForward = false;
							this.$set(this.lotUnitsError, 2, true)
						}

						if (!moveForward) {
							return false;
						}
					}

					const payload = { ...this.formData, email: this.formData.purchasers[0].email, form: this.worksheetForm.id };
					if (this.worksheetId) {
						if (this.worksheet.status === 'decline') {
							payload.status = 'pending'
						}
						this.onUpdate(payload);
					} else {
						this.onCreate(payload);
					}
				} else {
					return;
				}
			})
		},

		async onCreate(payload) {
			this.loading = true;
			try {
				await this.$api.post(`/worksheets/${this.instance.id}`, {
					...payload
				});
				this.$message.success('Worksheet created successfully!');
				this.goBack();
			} catch (err) {
				if (!err || !err.response || !err.response.status || err.response.status !== 400) {
					this.$message.error(this.$err(err, "Error occurred while creating worksheet. Please try again!"))
				}
			}
			this.loading = false;
		},

		async onUpdate(payload) {
			this.loading = true;
			try {
				await this.$api.put(`/worksheets/${this.instance.id}/${this.worksheetId}`, {
					...payload
				});
				this.$message.success('Worksheet updated successfully!')
				this.goBack();
			} catch (err) {
				if (!err || !err.response || !err.response.status || err.response.status !== 400) {
					this.$message.error(this.$err(err, "Error occurred while updating worksheet. Please try again!"))
				}
			}
			this.loading = false;
		},

		generateWorksheetData() {
			const fields = this.worksheetForm && this.worksheetForm.fields || []
			fields.forEach(field => {
				if (field.type === 'lot') {
					this.$set(this.formData, 'lots', new Array(3).fill(null))
					// setting units and packages fields for the lowrise worksheet
					this.$set(this.formData, 'units', new Array(3).fill(null))
					this.$set(this.formData, 'packages', new Array(3).fill(null))

					// set first lot choice if it is coming from IPM
					let lotId = this.$route.query.lot || null;
					if (lotId) {
						this.formData.lots[0] = lotId
					}
				} else if (field.type === 'unit') {
					this.$set(this.formData, 'units', new Array(3).fill(null))

					// set first unit choice if it is coming from IPM
					let unitId = this.$route.query.unit || null;
					if (unitId) {
						this.formData.units[0] = unitId
					}
				} else if (field.type === 'addOns') {
					this.$set(this.formData, 'addons', {
						parking: [],
						lockers: [],
						bikeRacks: []
					})
				} else if (field.type === 'purchaser') {
					if (!this.formData.purchasers) {
						this.$set(this.formData, 'purchasers', [])
					}
					// this.formData.purchasers.push({ ...this.defaultPurchaserFieldData })
				} else if (field.type === 'solicitor') {
					this.$set(this.formData, 'solicitor', {})
					// this.$set(this.formData, 'solicitor', { ...this.defaultSolicitorFieldData })
				} else if (field.type === 'email') {
					this.$set(this.formData, 'email', null)
				} else if (field.type === 'name') {
					this.$set(this.formData, 'name', { prefix: '', firstName: '', middleInitial: '', lastName: '', suffix: '' })
				} else if (field.type === 'phone') {
					this.$set(this.formData, 'phone', field.default || null)
				} else if (field.type === 'address') {
					this.$set(this.formData, 'address', { address1: '', address2: '', postal: '', country: '', region: '', city: '' })
				} else if (field.type === 'isAgent') {
					this.$set(this.formData, 'isAgent', field.default || null)
				} else {
					this.$set(this.formData, field.id, field.default || (field.multiple ? [] : null))
				}
			});
			this.isFormReady = true;
		},

		deleteWorksheet(worksheet) {
			let self = this;
			this.$confirm({
				title: "Delete Worksheet",
				content: (h) => <div>Are you sure you want to delete worksheet <strong>{worksheet.readableId}</strong>?</div>,
				okText: "DELETE",
				okType: "danger",
				cancelText: "CANCEL",
				centered: true,
				onOk: async () => {
					try {
						await self.$api.delete(`/worksheets/${self.instance.id}/${worksheet.id}`);
						self.goBack();
					} catch (err) {
						if (!err || !err.response || !err.response.status || err.response.status !== 400) {
							self.$message.error(self.$err(err, "Error occurred while deleting worksheet. Please Try Again!"))
						}
					}
				},
			});
		},

		handleChange(info, field) {
			if (info.file.status === 'uploading') {
				this.loading = true;
				return;
			}
			if (info.file.status === 'done') {
				// Get this url from response in real world.
				getBase64(info.file.originFileObj, imageUrl => {
					this.imageUrl = imageUrl;
					this.loading = false;
				});
			}

			this.loading = true
			let formData = new FormData()
			formData.append('files', info.file, info.file.name);
			formData.append('path', this.$bhapp);
			formData.append('instance', this.$store.state.instance.id);

			let url = `/upload`;
			this.$api.post(url, formData, { headers: { 'Content-Type': 'multipart/form-data' } }).then(({ data }) => {
				this.loading = false
				data = data[0]
				if (data.url) {
					this.formData[field.id] = data.url
				} else {
					this.$message.error('Oops. Something went wrong. Please try again')
				}
			}).catch(err => {
				this.loading = false
				if (!err || !err.response || !err.response.status || err.response.status !== 400) {
					this.$message.error(this.$err(err, "Error occurred while uploading the file into worksheet. Please try again!"))
				}
			})

		},

		beforeUpload(file, field) {
			if (field.type === 'images') {
				const isJpgOrPng = file.type.startsWith('image/');
				if (!isJpgOrPng) {
					this.$message.error('You can only upload Image(*.jpg,*.png etc) files!');
				}
				return isJpgOrPng;
			}

			if (field.type === 'documents') {
				const isDocument = file.type.startsWith('application/');
				if (!isDocument) {
					this.$message.error('You can only upload Document(*.pdf,*.docx etc) file!');
				}
				return isDocument;
			}

			const fileLimit = file.size / (1024 ** 2) < Number(field.maxSize);
			if (!fileLimit) {
				this.$message.error(`File must smaller than ${field.maxSize}MB!`);
			}
			return fileLimit;
		},

		goBack() {
			if (this.$store.state.sidebarSelection === 'Worksheets') {
				this.$router.push('/')
			} else {
				this.$router.push('/archived')
			}
		},

		disabledDate(current) {
			return current && current > moment().startOf('day')
		},

		disabledExpiryDate(current) {
			return current && current < moment().startOf('day')
		},

		classLookup(status) {
			let lookup = {
				pending: 'bg-med-gray text-white',
				approved: 'bg-teal text-white',
				reserved: 'bg-dark-green text-white',
				declined: 'bg-red text-white',
				void: 'bg-red text-white',
				draft: 'bg-gray text-white',
				sold: 'bg-pink text-white',
			}
			let textLookup = {
				void: 'Voided'
			}
			return {
				color: lookup[status] || 'badge',
				text: textLookup[status] || status,
			}
		},

		createNew() {
			this.$refs.createNewWorksheet.validate(async (valid) => {
				if (valid) {
					const selectedWorksheetForm = this.worksheetForms.find(w => w.id === this.createNewWorksheet.id)
					if (selectedWorksheetForm) {
						this.$store.commit('SET_WORKSHEET', null)
						this.$store.commit('SET_WORKSHEET_FORM', selectedWorksheetForm)
						this.worksheetFormModal = false;
						this.createNewWorksheet.id = null;
						this.generateWorksheetData()
					}
				}
			})
		},

		setValidatingFn(validateFn) {
			this.purchaserFieldValidateFn = validateFn
		},
	},

	mounted() {
		if (!this.isAgent && !this.$route.params.id) {
			this.$message.error('Only agent can submit new worksheet.');
			this.goBack();
		}

		if (!this.worksheetForm && !this.$route.params.id) {
			if (!this.worksheetForms.length) {
				this.$message.error('Worksheet form is not set up yet. Please contact owner.');
				this.goBack();
			}
			this.createNewWorksheet.id = this.worksheetForms[0].id;
			this.worksheetFormModal = true;
		}
	},

	beforeDestroy() {
		this.$store.commit('SET_WORKSHEET_FORM', null)
		this.$store.commit('SET_WORKSHEET', null)
	}
};
</script>

<style lang="scss" scoped>
</style>

<style lang="scss">
.worksheetTabs .ant-divider-horizontal {
	margin: 10px 0 20px;
}

.worksheetTabs .ant-tabs-nav {
	font-size: 18px;

	.ant-tabs-tab {
		padding-left: 25px;
		padding-right: 25px;
	}
}

.worksheetTabs .ant-tabs-bar {
	margin: 0 0 25px 0;
}
</style>
